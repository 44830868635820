import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import car from './modules/car'
import auth from './modules/auth'
import make from './modules/make'
import user from './modules/user'
import client from './modules/client'
import filter from './modules/filter'
import request from './modules/request'
import session from './modules/session'
import filter_value from './modules/filter_value'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    car,
    auth,
    make,
    user,
    client,
    filter,
    request,
    session,
    filter_value,
  },
  plugins: [createPersistedState()],
})
