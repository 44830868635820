import axios from '@/axios'
import router from '@/router'
import { showNotification } from '@/utils'

import {
  CAR,
  CARS,
  ADD_CAR,
  GET_CAR,
  SET_CAR,
  GET_CARS,
  SAVE_CAR,
  SET_CARS,
  DELETE_CAR,
  UPDATE_CAR,
  CARS_TOTAL,
  SET_CARS_TOTAL,
} from '@/store/types'

export default {
  state: {
    car: {},
    cars: [],
    carsTotal: 0,
  },
  getters: {
    [CAR]: state => state.car,
    [CARS]: state => state.cars,
    [CARS_TOTAL]: state => state.carsTotal,
  },
  mutations: {
    [ADD_CAR]: (state, car) => state.cars.unshift(car),
    [SET_CAR]: (state, car) => (state.car = car),
    [SET_CARS]: (state, cars) => (state.cars = cars),
    [SET_CARS_TOTAL]: (state, total) => (state.carsTotal = total),
    [UPDATE_CAR]: (state, updatedCar) => {
      const carIndex = state.cars.findIndex(car => car.id === updatedCar.id)

      carIndex !== -1 && state.cars.splice(carIndex, 1, updatedCar)
    },
    [DELETE_CAR]: (state, deletedCar) => {
      const carIndex = state.cars.findIndex(car => car.id === deletedCar.id)

      carIndex !== -1 && state.cars.splice(carIndex, 1)
    },
  },
  actions: {
    [SAVE_CAR]: async ({ commit }, car) => {
      try {
        const { data } = await axios.post('/cars/new', {
          ...car,
        })

        commit(ADD_CAR, data)

        // eslint-disable-next-line no-unused-vars
        router.push({ name: 'cars' }).catch(_err => {})

        showNotification('Успешно', 'Транзакция успешно сохранена', 'success')
      } catch (error) {
        showNotification(
          'Ошибка',
          error.response ? error.response.data.message : error.message,
          'error',
        )
      }
    },
    [UPDATE_CAR]: async ({ commit }, car) => {
      try {
        const { data } = await axios.patch(`/cars/${car.id}`, {
          ...car,
        })

        commit(UPDATE_CAR, data)

        // eslint-disable-next-line no-unused-vars
        router.push({ name: 'cars' }).catch(_err => {})

        showNotification('Успешно', 'Транзакция успешно обновлена', 'success')
      } catch (error) {
        showNotification(
          'Ошибка',
          error.response ? error.response.data.message : error.message,
          'error',
        )
      }
    },
    [DELETE_CAR]: async ({ commit }, car) => {
      try {
        await axios.delete(`/cars/${car.id}`)

        commit(DELETE_CAR, car)

        showNotification('Успешно', 'Транзакция успешно удалена', 'success')
      } catch (error) {
        showNotification(
          'Ошибка',
          error.response ? error.response.data.message : error.message,
          'error',
        )
      }
    },
    [GET_CAR]: async ({ commit }, carId) => {
      try {
        const { data } = await axios.get(`/cars/${carId}`)

        commit(SET_CAR, data)

        return data
      } catch (error) {
        showNotification(
          'Ошибка',
          error.response ? error.response.data.message : error.message,
          'error',
        )
      }
    },
    [GET_CARS]: async ({ commit }, params) => {
      try {
        const { data } = await axios.post('/cars', { params })

        commit(SET_CARS, data.result)
        commit(SET_CARS_TOTAL, data.total)
      } catch (error) {
        showNotification(
          'Ошибка',
          error.response ? error.response.data.message : error.message,
          'error',
        )
      }
    },
  },
}
