import axios from '@/axios'
import router from '@/router'
import { showNotification } from '@/utils'

import {
  CLIENT,
  CLIENTS,
  ADD_CLIENT,
  GET_CLIENT,
  SET_CLIENT,
  GET_CLIENTS,
  SAVE_CLIENT,
  SET_CLIENTS,
  DELETE_CLIENT,
  UPDATE_CLIENT,
  CLIENTS_TOTAL,
  SET_CLIENTS_TOTAL,
} from '@/store/types'

export default {
  state: {
    client: {},
    clients: [],
    clientsTotal: 0,
  },
  getters: {
    [CLIENT]: state => state.client,
    [CLIENTS]: state => state.clients,
    [CLIENTS_TOTAL]: state => state.clientsTotal,
  },
  mutations: {
    [ADD_CLIENT]: (state, client) => state.clients.unshift(client),
    [SET_CLIENT]: (state, client) => (state.client = client),
    [SET_CLIENTS]: (state, clients) => (state.clients = clients),
    [SET_CLIENTS_TOTAL]: (state, total) => (state.clientsTotal = total),
    [UPDATE_CLIENT]: (state, updatedClient) => {
      const clientIndex = state.clients.findIndex(
        client => client.id === updatedClient.id,
      )

      clientIndex !== -1 && state.clients.splice(clientIndex, 1, updatedClient)
    },
    [DELETE_CLIENT]: (state, deletedClient) => {
      const clientIndex = state.clients.findIndex(
        client => client.id === deletedClient.id,
      )

      clientIndex !== -1 && state.clients.splice(clientIndex, 1)
    },
  },
  actions: {
    [SAVE_CLIENT]: async ({ commit }, client) => {
      try {
        const { data } = await axios.post('/clients/new', {
          ...client,
        })

        commit(ADD_CLIENT, data)

        // eslint-disable-next-line no-unused-vars
        router.push({ name: 'clients' }).catch(_err => {})

        showNotification('Успешно', 'Клиент успешно сохранён', 'success')
      } catch (error) {
        showNotification(
          'Ошибка',
          error.response ? error.response.data.message : error.message,
          'error',
        )
      }
    },
    [UPDATE_CLIENT]: async ({ commit }, client) => {
      try {
        const { data } = await axios.patch(`/clients/${client.id}`, {
          ...client,
        })

        commit(UPDATE_CLIENT, data)

        // eslint-disable-next-line no-unused-vars
        router.push({ name: 'clients' }).catch(_err => {})

        showNotification('Успешно', 'Клиент успешно обновлён', 'success')
      } catch (error) {
        showNotification(
          'Ошибка',
          error.response ? error.response.data.message : error.message,
          'error',
        )
      }
    },
    [DELETE_CLIENT]: async ({ commit }, client) => {
      try {
        await axios.delete(`/clients/${client.id}`)

        commit(DELETE_CLIENT, client)

        showNotification('Успешно', 'Клиент успешно удалён', 'success')
      } catch (error) {
        showNotification(
          'Ошибка',
          error.response ? error.response.data.message : error.message,
          'error',
        )
      }
    },
    [GET_CLIENT]: async ({ commit }, clientId) => {
      try {
        const { data } = await axios.get(`/clients/${clientId}`)

        commit(SET_CLIENT, data)

        return data
      } catch (error) {
        showNotification(
          'Ошибка',
          error.response ? error.response.data.message : error.message,
          'error',
        )
      }
    },
    [GET_CLIENTS]: async ({ commit }, params) => {
      try {
        const { data } = await axios.post('/clients', { params })

        commit(SET_CLIENTS, data.result)
        commit(SET_CLIENTS_TOTAL, data.total)
      } catch (error) {
        showNotification(
          'Ошибка',
          error.response ? error.response.data.message : error.message,
          'error',
        )
      }
    },
  },
}
