import Vue from 'vue'
import Meta from 'vue-meta'
import store from '@/store'
import Router from 'vue-router'

import {
  LOGOUT_USER,
  AUTH_USER_IS_ADMIN,
  AUTH_USER_IS_SUPPORT,
  AUTH_USER_IS_LOGGED_IN,
} from '@/store/types'

Vue.use(Router)
Vue.use(Meta, {
  refreshOnceOnNavigation: true,
})

const routes = [
  {
    path: '/logout',
    name: 'logout',
    meta: {
      isAuth: true,
    },
    beforeEnter: (to, from, next) => {
      store.dispatch(LOGOUT_USER)
      next()
    },
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      isGuest: true,
    },
    components: {
      login: () => import('@/views/Login'),
    },
  },
  {
    path: '/',
    name: 'dashboard',
    meta: {
      isAuth: true,
    },
    components: {
      dashboard: () => import('@/views/Dashboard'),
    },
    children: [
      {
        path: 'users',
        name: 'users',
        meta: { parents: ['users-panel'], isAuth: true },
        component: () => import('@/components/users'),
      },
      {
        path: 'users/new',
        name: 'users-new',
        meta: {
          parents: ['users-panel', 'users'],
          isAuth: true,
          isAdmin: true,
        },
        component: () => import('@/components/users/New'),
      },
      {
        path: 'users/:id',
        name: 'users-edit',
        meta: { parents: ['users-panel', 'users'], isAuth: true },
        component: () => import('@/components/users/Edit'),
      },

      {
        path: 'sessions',
        name: 'sessions',
        meta: {
          parents: ['users-panel'],
          isAuth: true,
          isAdmin: true,
        },
        component: () => import('@/components/sessions/index'),
      },

      // {
      //   path: 'cars',
      //   name: 'cars',
      //   meta: { parents: ['cars-panel'], isAuth: true },
      //   component: () => import('@/components/cars'),
      // },
      // {
      //   path: 'cars/:id',
      //   name: 'cars-edit',
      //   meta: {
      //     parents: ['cars-panel'],
      //     isAuth: true,
      //     isAdmin: true,
      //   },
      //   component: () => import('@/components/cars/Edit'),
      // },

      {
        path: 'makes',
        name: 'makes',
        meta: { parents: ['filters-panel'], isAuth: true, isAdmin: true },
        component: () => import('@/components/makes'),
      },
      {
        path: 'makes/new',
        name: 'makes-new',
        meta: {
          parents: ['filters-panel', 'makes'],
          isAuth: true,
          isAdmin: true,
        },
        component: () => import('@/components/makes/New'),
      },
      {
        path: 'makes/:id',
        name: 'makes-edit',
        meta: {
          parents: ['filters-panel', 'makes'],
          isAuth: true,
          isAdmin: true,
        },
        component: () => import('@/components/makes/Edit'),
      },

      {
        path: 'filters',
        name: 'filters-all',
        meta: { parents: ['filters-panel'], isAuth: true, isAdmin: true },
        component: () => import('@/components/filters'),
      },
      {
        path: 'filters/new',
        name: 'filters-new',
        meta: {
          parents: ['filters-panel', 'filters-all'],
          isAuth: true,
          isAdmin: true,
        },
        component: () => import('@/components/filters/New'),
      },
      {
        path: 'filters/:id',
        name: 'filters-edit',
        meta: {
          parents: ['filters-panel', 'filters-all'],
          isAuth: true,
          isAdmin: true,
        },
        component: () => import('@/components/filters/Edit'),
      },

      {
        path: 'filter-values',
        name: 'filters-values',
        meta: { parents: ['filters-panel'], isAuth: true, isAdmin: true },
        component: () => import('@/components/filter-values'),
      },
      {
        path: 'filter-values/new',
        name: 'filter-values-new',
        meta: {
          parents: ['filters-panel', 'filters-values'],
          isAuth: true,
          isAdmin: true,
        },
        component: () => import('@/components/filter-values/New'),
      },
      {
        path: 'filter-values/:id',
        name: 'filter-values-edit',
        meta: {
          parents: ['filters-panel', 'filters-values'],
          isAuth: true,
          isAdmin: true,
        },
        component: () => import('@/components/filter-values/Edit'),
      },

      {
        path: 'requests',
        name: 'requests',
        meta: { parents: ['requests-panel'], isAuth: true, isAdmin: true },
        component: () => import('@/components/requests'),
      },
      {
        path: 'requests/:id',
        name: 'requests-edit',
        meta: {
          parents: ['requests-panel', 'requests'],
          isAuth: true,
          isAdmin: true,
        },
        component: () => import('@/components/requests/Edit'),
      },

      {
        path: 'clients',
        name: 'clients',
        meta: { parents: ['users-panel'], isAuth: true, isAdmin: true },
        component: () => import('@/components/clients'),
      },
      {
        path: 'clients/:id',
        name: 'clients-edit',
        meta: {
          parents: ['users-panel', 'clients'],
          isAuth: true,
          isAdmin: true,
        },
        component: () => import('@/components/clients/Edit'),
      },
    ],
  },
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, _from, next) => {
  if (to.matched.some(route => route.meta.isAuth)) {
    if (!store.getters[AUTH_USER_IS_LOGGED_IN]) {
      return next({ name: 'login' })
    } else {
      return next()
    }
  }

  if (to.matched.some(route => route.meta.isGuest)) {
    if (store.getters[AUTH_USER_IS_LOGGED_IN]) {
      return next({ name: 'dashboard' })
    } else {
      return next()
    }
  }

  if (to.matched.some(route => route.meta.isAdmin)) {
    if (store.getters[AUTH_USER_IS_ADMIN]) {
      return next()
    } else {
      return next(false)
    }
  }

  if (to.matched.some(route => route.meta.isSupport)) {
    if (store.getters[AUTH_USER_IS_SUPPORT]) {
      return next()
    } else {
      return next(false)
    }
  }

  return next()
})

router.beforeResolve((to, _from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    window.NProgress.start()
  }
  next()
})

// eslint-disable-next-line no-unused-vars
router.afterEach((_to, _from) => {
  // Complete the animation of the route progress bar.
  window.NProgress.done()
})

export default router
