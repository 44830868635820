import axios from '@/axios'
import router from '@/router'
import { showNotification } from '@/utils'

import {
  MAKE,
  MAKES,
  ADD_MAKE,
  GET_MAKE,
  SET_MAKE,
  GET_MAKES,
  SAVE_MAKE,
  SET_MAKES,
  DELETE_MAKE,
  UPDATE_MAKE,
  MAKES_TOTAL,
  SET_MAKES_TOTAL,
} from '@/store/types'

export default {
  state: {
    make: {},
    makes: [],
    makesTotal: 0,
  },
  getters: {
    [MAKE]: state => state.make,
    [MAKES]: state => state.makes,
    [MAKES_TOTAL]: state => state.makesTotal,
  },
  mutations: {
    [ADD_MAKE]: (state, make) => state.makes.unshift(make),
    [SET_MAKE]: (state, make) => (state.make = make),
    [SET_MAKES]: (state, makes) => (state.makes = makes),
    [SET_MAKES_TOTAL]: (state, total) => (state.makesTotal = total),
    [UPDATE_MAKE]: (state, updatedMake) => {
      const makeIndex = state.makes.findIndex(
        make => make.id === updatedMake.id,
      )

      makeIndex !== -1 && state.makes.splice(makeIndex, 1, updatedMake)
    },
    [DELETE_MAKE]: (state, deletedMake) => {
      const makeIndex = state.makes.findIndex(
        make => make.id === deletedMake.id,
      )

      makeIndex !== -1 && state.makes.splice(makeIndex, 1)
    },
  },
  actions: {
    [SAVE_MAKE]: async ({ commit }, make) => {
      try {
        const { data } = await axios.post('/makes/new', {
          ...make,
        })

        commit(ADD_MAKE, data)

        // eslint-disable-next-line no-unused-vars
        router.push({ name: 'makes' }).catch(_err => {})

        showNotification('Успешно', 'Марка успешно сохранена', 'success')
      } catch (error) {
        showNotification(
          'Ошибка',
          error.response ? error.response.data.message : error.message,
          'error',
        )
      }
    },
    [UPDATE_MAKE]: async ({ commit }, make) => {
      try {
        const { data } = await axios.patch(`/makes/${make.id}`, {
          ...make,
        })

        commit(UPDATE_MAKE, data)

        // eslint-disable-next-line no-unused-vars
        router.push({ name: 'makes' }).catch(_err => {})

        showNotification('Успешно', 'Марка успешно обновлена', 'success')
      } catch (error) {
        showNotification(
          'Ошибка',
          error.response ? error.response.data.message : error.message,
          'error',
        )
      }
    },
    [DELETE_MAKE]: async ({ commit }, make) => {
      try {
        await axios.delete(`/makes/${make.id}`)

        commit(DELETE_MAKE, make)

        showNotification('Успешно', 'Марка успешно удалена', 'success')
      } catch (error) {
        showNotification(
          'Ошибка',
          error.response ? error.response.data.message : error.message,
          'error',
        )
      }
    },
    [GET_MAKE]: async ({ commit }, makeId) => {
      try {
        const { data } = await axios.get(`/makes/${makeId}`)

        commit(SET_MAKE, data)

        return data
      } catch (error) {
        showNotification(
          'Ошибка',
          error.response ? error.response.data.message : error.message,
          'error',
        )
      }
    },
    [GET_MAKES]: async ({ commit }, params) => {
      try {
        const { data } = await axios.post('/makes', { params })

        commit(SET_MAKES, data.result)
        commit(SET_MAKES_TOTAL, data.total)
      } catch (error) {
        showNotification(
          'Ошибка',
          error.response ? error.response.data.message : error.message,
          'error',
        )
      }
    },
  },
}
