import axios from '@/axios'
import router from '@/router'
import { showNotification } from '@/utils'
import {
  USER,
  USERS,
  ADD_USER,
  GET_USER,
  SET_USER,
  GET_USERS,
  SAVE_USER,
  SET_USERS,
  DELETE_USER,
  UPDATE_USER,
  USERS_TOTAL,
  SET_USERS_TOTAL,
  UPDATE_USER_PASSWORD,
} from '@/store/types'

export default {
  state: {
    user: {},
    users: [],
    usersTotal: 0,
  },

  getters: {
    [USER]: state => state.user,
    [USERS]: state => state.users,
    [USERS_TOTAL]: state => state.usersTotal,
  },

  mutations: {
    [ADD_USER]: (state, user) => state.users.unshift(user),
    [SET_USER]: (state, user) => (state.user = user),
    [SET_USERS]: (state, users) => (state.users = users),
    [SET_USERS_TOTAL]: (state, total) => (state.usersTotal = total),
    [UPDATE_USER]: (state, updatedUser) => {
      const userIndex = state.users.findIndex(
        user => user.id === updatedUser.id,
      )

      userIndex !== -1 && state.users.splice(userIndex, 1, updatedUser)
    },
    [DELETE_USER]: (state, deletedUser) => {
      const userIndex = state.users.findIndex(
        user => user.id === deletedUser.id,
      )

      userIndex !== -1 && state.users.splice(userIndex, 1)
    },
  },

  actions: {
    [SAVE_USER]: async ({ commit }, user) => {
      try {
        const { data } = await axios.post('/users/new', user)

        commit(ADD_USER, data)

        // eslint-disable-next-line no-unused-vars
        router.push({ name: 'users' }).catch(_err => {})

        showNotification('Успешно', 'Сотрудник успешно сохранён', 'success')
      } catch (error) {
        showNotification(
          'Ошибка',
          error.response ? error.response.data.message : error.message,
          'error',
        )
      }
    },
    [UPDATE_USER]: async ({ commit }, user) => {
      try {
        const { data } = await axios.patch(`/users/${user.id}`, {
          ...user,
        })

        commit(UPDATE_USER, data)

        showNotification('Успешно', 'Сотрудник успешно обновлён', 'success')
      } catch (error) {
        showNotification(
          'Ошибка',
          error.response ? error.response.data.message : error.message,
          'error',
        )
      }
    },

    [UPDATE_USER_PASSWORD]: async (_ctx, user) => {
      try {
        const { data } = await axios.patch(`/users/${user.id}/password`, {
          ...user,
        })

        if (!data.result) {
          return showNotification(
            'Ошибка',
            'Не удалось обновить пароль',
            'error',
          )
        }

        showNotification('Успешно', 'Пароль успешно обновлён', 'success')

        // eslint-disable-next-line no-unused-vars
        return router.push({ name: 'dashboard' }).catch(_err => {})
      } catch (error) {
        showNotification(
          'Ошибка',
          error.response ? error.response.data.message : error.message,
          'error',
        )
      }
    },

    [DELETE_USER]: async ({ commit }, user) => {
      try {
        await axios.delete(`/users/${user.id}`)

        commit(DELETE_USER, user)

        showNotification('Успешно', 'Сотрудник успешно удалён', 'success')
      } catch (error) {
        showNotification(
          'Ошибка',
          error.response ? error.response.data.message : error.message,
          'error',
        )
      }
    },
    [GET_USER]: async ({ commit }, userId) => {
      try {
        const { data } = await axios.get(`/users/${userId}`)

        commit(SET_USER, data)

        return data
      } catch (error) {
        showNotification(
          'Ошибка',
          error.response ? error.response.data.message : error.message,
          'error',
        )
      }
    },
    [GET_USERS]: async ({ commit }, params) => {
      try {
        const { data } = await axios.post('/users', { params })

        commit(SET_USERS, data.result)
        commit(SET_USERS_TOTAL, data.total)
      } catch (error) {
        showNotification(
          'Ошибка',
          error.response ? error.response.data.message : error.message,
          'error',
        )
      }
    },
  },
}
