import axios from '@/axios'
import router from '@/router'
import { showNotification } from '@/utils'

import {
  FILTER,
  FILTERS,
  ADD_FILTER,
  GET_FILTER,
  SET_FILTER,
  GET_FILTERS,
  SAVE_FILTER,
  SET_FILTERS,
  DELETE_FILTER,
  UPDATE_FILTER,
  FILTERS_TOTAL,
  SET_FILTERS_TOTAL,
} from '@/store/types'

export default {
  state: {
    filter: {},
    filters: [],
    filtersTotal: 0,
  },
  getters: {
    [FILTER]: state => state.filter,
    [FILTERS]: state => state.filters,
    [FILTERS_TOTAL]: state => state.filtersTotal,
  },
  mutations: {
    [ADD_FILTER]: (state, filter) => state.filters.unshift(filter),
    [SET_FILTER]: (state, filter) => (state.filter = filter),
    [SET_FILTERS]: (state, filters) => (state.filters = filters),
    [SET_FILTERS_TOTAL]: (state, total) => (state.filtersTotal = total),
    [UPDATE_FILTER]: (state, updatedFilter) => {
      const filterIndex = state.filters.findIndex(
        filter => filter.id === updatedFilter.id,
      )

      filterIndex !== -1 && state.filters.splice(filterIndex, 1, updatedFilter)
    },
    [DELETE_FILTER]: (state, deletedFilter) => {
      const filterIndex = state.filters.findIndex(
        filter => filter.id === deletedFilter.id,
      )

      filterIndex !== -1 && state.filters.splice(filterIndex, 1)
    },
  },
  actions: {
    [SAVE_FILTER]: async ({ commit }, filter) => {
      try {
        const { data } = await axios.post('/filters/new', {
          ...filter,
        })

        commit(ADD_FILTER, data)

        // eslint-disable-next-line no-unused-vars
        router.push({ name: 'filters-all' }).catch(_err => {})

        showNotification('Успешно', 'Фильтр успешно сохранён', 'success')
      } catch (error) {
        showNotification(
          'Ошибка',
          error.response ? error.response.data.message : error.message,
          'error',
        )
      }
    },
    [UPDATE_FILTER]: async ({ commit }, filter) => {
      try {
        const { data } = await axios.patch(`/filters/${filter.id}`, {
          ...filter,
        })

        commit(UPDATE_FILTER, data)

        // eslint-disable-next-line no-unused-vars
        router.push({ name: 'filters-all' }).catch(_err => {})

        showNotification('Успешно', 'Фильтр успешно обновлён', 'success')
      } catch (error) {
        showNotification(
          'Ошибка',
          error.response ? error.response.data.message : error.message,
          'error',
        )
      }
    },
    [DELETE_FILTER]: async ({ commit }, filter) => {
      try {
        await axios.delete(`/filters/${filter.id}`)

        commit(DELETE_FILTER, filter)

        showNotification('Успешно', 'Фильтр успешно удалён', 'success')
      } catch (error) {
        showNotification(
          'Ошибка',
          error.response ? error.response.data.message : error.message,
          'error',
        )
      }
    },
    [GET_FILTER]: async ({ commit }, filterId) => {
      try {
        const { data } = await axios.get(`/filters/${filterId}`)

        commit(SET_FILTER, data)

        return data
      } catch (error) {
        showNotification(
          'Ошибка',
          error.response ? error.response.data.message : error.message,
          'error',
        )
      }
    },
    [GET_FILTERS]: async ({ commit }, params) => {
      try {
        const { data } = await axios.post('/filters', { params })

        commit(SET_FILTERS, data.result)
        commit(SET_FILTERS_TOTAL, data.total)
      } catch (error) {
        showNotification(
          'Ошибка',
          error.response ? error.response.data.message : error.message,
          'error',
        )
      }
    },
  },
}
