import axios from '@/axios'
import router from '@/router'
import { showNotification } from '@/utils'

import {
  REQUEST,
  REQUESTS,
  ADD_REQUEST,
  GET_REQUEST,
  SET_REQUEST,
  GET_REQUESTS,
  SAVE_REQUEST,
  SET_REQUESTS,
  DELETE_REQUEST,
  UPDATE_REQUEST,
  REQUESTS_TOTAL,
  SET_REQUESTS_TOTAL,
} from '@/store/types'

export default {
  state: {
    request: {},
    requests: [],
    requestsTotal: 0,
  },
  getters: {
    [REQUEST]: state => state.request,
    [REQUESTS]: state => state.requests,
    [REQUESTS_TOTAL]: state => state.requestsTotal,
  },
  mutations: {
    [ADD_REQUEST]: (state, request) => state.requests.unshift(request),
    [SET_REQUEST]: (state, request) => (state.request = request),
    [SET_REQUESTS]: (state, requests) => (state.requests = requests),
    [SET_REQUESTS_TOTAL]: (state, total) => (state.requestsTotal = total),
    [UPDATE_REQUEST]: (state, updatedRequest) => {
      const requestIndex = state.requests.findIndex(
        request => request.id === updatedRequest.id,
      )

      requestIndex !== -1 &&
        state.requests.splice(requestIndex, 1, updatedRequest)
    },
    [DELETE_REQUEST]: (state, deletedRequest) => {
      const requestIndex = state.requests.findIndex(
        request => request.id === deletedRequest.id,
      )

      requestIndex !== -1 && state.requests.splice(requestIndex, 1)
    },
  },
  actions: {
    [SAVE_REQUEST]: async ({ commit }, request) => {
      try {
        const { data } = await axios.post('/requests/new', {
          ...request,
        })

        commit(ADD_REQUEST, data)

        // eslint-disable-next-line no-unused-vars
        router.push({ name: 'requests' }).catch(_err => {})

        showNotification('Успешно', 'Заявка успешно сохранена', 'success')
      } catch (error) {
        showNotification(
          'Ошибка',
          error.response ? error.response.data.message : error.message,
          'error',
        )
      }
    },
    [UPDATE_REQUEST]: async ({ commit }, request) => {
      try {
        const { data } = await axios.patch(`/requests/${request.id}`, {
          ...request,
        })

        commit(UPDATE_REQUEST, data)

        // eslint-disable-next-line no-unused-vars
        router.push({ name: 'requests' }).catch(_err => {})

        showNotification('Успешно', 'Заявка успешно обновлена', 'success')
      } catch (error) {
        showNotification(
          'Ошибка',
          error.response ? error.response.data.message : error.message,
          'error',
        )
      }
    },
    [DELETE_REQUEST]: async ({ commit }, request) => {
      try {
        await axios.delete(`/requests/${request.id}`)

        commit(DELETE_REQUEST, request)

        showNotification('Успешно', 'Заявка успешно удалена', 'success')
      } catch (error) {
        showNotification(
          'Ошибка',
          error.response ? error.response.data.message : error.message,
          'error',
        )
      }
    },
    [GET_REQUEST]: async ({ commit }, requestId) => {
      try {
        const { data } = await axios.get(`/requests/${requestId}`)

        commit(SET_REQUEST, data)

        return data
      } catch (error) {
        showNotification(
          'Ошибка',
          error.response ? error.response.data.message : error.message,
          'error',
        )
      }
    },
    [GET_REQUESTS]: async ({ commit }, params) => {
      try {
        const { data } = await axios.post('/requests', { params })

        commit(SET_REQUESTS, data.result)
        commit(SET_REQUESTS_TOTAL, data.total)
      } catch (error) {
        showNotification(
          'Ошибка',
          error.response ? error.response.data.message : error.message,
          'error',
        )
      }
    },
  },
}
