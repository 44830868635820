import axios from '@/axios'
import router from '@/router'
import { showNotification } from '@/utils'

import {
  FILTER_VALUE,
  FILTER_VALUES,
  ADD_FILTER_VALUE,
  GET_FILTER_VALUE,
  SET_FILTER_VALUE,
  GET_FILTER_VALUES,
  SAVE_FILTER_VALUE,
  SET_FILTER_VALUES,
  DELETE_FILTER_VALUE,
  UPDATE_FILTER_VALUE,
  FILTER_VALUES_TOTAL,
  SET_FILTER_VALUES_TOTAL,
} from '@/store/types'

export default {
  state: {
    filterValue: {},
    filterValues: [],
    filterValuesTotal: 0,
  },
  getters: {
    [FILTER_VALUE]: state => state.filterValue,
    [FILTER_VALUES]: state => state.filterValues,
    [FILTER_VALUES_TOTAL]: state => state.filterValuesTotal,
  },
  mutations: {
    [ADD_FILTER_VALUE]: (state, filterValue) =>
      state.filterValues.unshift(filterValue),
    [SET_FILTER_VALUE]: (state, filterValue) =>
      (state.filterValue = filterValue),
    [SET_FILTER_VALUES]: (state, filterValues) =>
      (state.filterValues = filterValues),
    [SET_FILTER_VALUES_TOTAL]: (state, total) =>
      (state.filterValuesTotal = total),
    [UPDATE_FILTER_VALUE]: (state, updatedFilterValue) => {
      const filterValueIndex = state.filterValues.findIndex(
        filterValue => filterValue.id === updatedFilterValue.id,
      )

      filterValueIndex !== -1 &&
        state.filterValues.splice(filterValueIndex, 1, updatedFilterValue)
    },
    [DELETE_FILTER_VALUE]: (state, deletedFilterValue) => {
      const filterValueIndex = state.filterValues.findIndex(
        filterValue => filterValue.id === deletedFilterValue.id,
      )

      filterValueIndex !== -1 && state.filterValues.splice(filterValueIndex, 1)
    },
  },
  actions: {
    [SAVE_FILTER_VALUE]: async ({ commit }, filterValue) => {
      try {
        const { data } = await axios.post('/filter-values/new', {
          ...filterValue,
        })

        commit(ADD_FILTER_VALUE, data)

        // eslint-disable-next-line no-unused-vars
        router.push({ name: 'filters-values' }).catch(_err => {})

        showNotification('Успешно', 'Фильтр успешно сохранён', 'success')
      } catch (error) {
        showNotification(
          'Ошибка',
          error.response ? error.response.data.message : error.message,
          'error',
        )
      }
    },
    [UPDATE_FILTER_VALUE]: async ({ commit }, filterValue) => {
      try {
        const { data } = await axios.patch(`/filter-values/${filterValue.id}`, {
          ...filterValue,
        })

        commit(UPDATE_FILTER_VALUE, data)

        // eslint-disable-next-line no-unused-vars
        router.push({ name: 'filters-values' }).catch(_err => {})

        showNotification('Успешно', 'Фильтр успешно обновлён', 'success')
      } catch (error) {
        showNotification(
          'Ошибка',
          error.response ? error.response.data.message : error.message,
          'error',
        )
      }
    },
    [DELETE_FILTER_VALUE]: async ({ commit }, filterValue) => {
      try {
        await axios.delete(`/filter-values/${filterValue.id}`)

        commit(DELETE_FILTER_VALUE, filterValue)

        showNotification('Успешно', 'Фильтр успешно удалён', 'success')
      } catch (error) {
        showNotification(
          'Ошибка',
          error.response ? error.response.data.message : error.message,
          'error',
        )
      }
    },
    [GET_FILTER_VALUE]: async ({ commit }, filterValueId) => {
      try {
        const { data } = await axios.get(`/filter-values/${filterValueId}`)

        commit(SET_FILTER_VALUE, data)

        return data
      } catch (error) {
        showNotification(
          'Ошибка',
          error.response ? error.response.data.message : error.message,
          'error',
        )
      }
    },
    [GET_FILTER_VALUES]: async ({ commit }, params) => {
      try {
        const { data } = await axios.post('/filter-values', { params })

        commit(SET_FILTER_VALUES, data.result)
        commit(SET_FILTER_VALUES_TOTAL, data.total)
      } catch (error) {
        showNotification(
          'Ошибка',
          error.response ? error.response.data.message : error.message,
          'error',
        )
      }
    },
  },
}
