import axios from '@/axios'
import router from '@/router'
import { showNotification } from '@/utils'
import {
  AUTH_USER,
  LOGIN_USER,
  LOGOUT_USER,
  SET_AUTH_USER,
  AUTH_USER_IS_ADMIN,
  AUTH_USER_IS_SUPPORT,
  AUTH_USER_IS_LOGGED_IN,
} from '../types'

export default {
  state: {
    authUser: {},
  },
  getters: {
    [AUTH_USER]: state => state.authUser,
    [AUTH_USER_IS_LOGGED_IN]: state => state.authUser.username,
    [AUTH_USER_IS_ADMIN]: state =>
      state.authUser.roles && state.authUser.roles.some(role => role.id === 1),
    [AUTH_USER_IS_SUPPORT]: state =>
      state.authUser.roles && state.authUser.roles.some(role => role.id === 4),
  },
  mutations: {
    [SET_AUTH_USER]: (state, user) => {
      state.authUser = user
    },
  },
  actions: {
    [LOGOUT_USER]: async ({ commit }) => {
      try {
        commit(SET_AUTH_USER, {})

        await axios.post('/logout')

        // eslint-disable-next-line no-unused-vars
        router.push({ name: 'login' }).catch(_err => {})
      } catch (error) {
        showNotification(
          'Ошибка',
          error.response ? error.response.data.message : error.message,
          'error',
        )
      }
    },
    [LOGIN_USER]: async ({ commit }, credentials) => {
      try {
        const { data } = await axios.post('/login', credentials)

        commit(SET_AUTH_USER, data.user)

        // eslint-disable-next-line no-unused-vars
        router.push({ name: 'dashboard' }).catch(_err => {})
      } catch (error) {
        showNotification(
          'Ошибка',
          error.response ? error.response.data.message : error.message,
          'error',
        )

        return error
      }
    },
  },
}
