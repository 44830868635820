import axios from 'axios'
import store from './store'
import { LOGOUT_USER } from './store/types'

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
})

instance.interceptors.request.use(
  config => {
    window.NProgress.start()

    return config
  },
  error => {
    window.NProgress.done()

    return Promise.reject(error)
  },
)

instance.interceptors.response.use(
  response => {
    window.NProgress.done()

    return response
  },
  error => {
    window.NProgress.done()

    if (error.response && error.response.status === 401) {
      store.dispatch(LOGOUT_USER)
    }

    return Promise.reject(error)
  },
)

export default instance
