import '@/axios'
import 'ant-design-vue/dist/antd.css'

import Vue from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue'
import store from './store'
import router from './router'

window.NProgress.configure({ easing: 'ease', speed: 500, showSpinner: false })

Vue.use(Antd)
Vue.config.productionTip = false

Vue.filter('capitalize', function(value) {
  if (!value) return ''

  const words = value.split(' ')

  if (words.length) {
    return words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  } else {
    return value.charAt(0).toUpperCase() + value.slice(1)
  }
})

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
