////////////////////////// AUTH //////////////////////////
// Getters
export const AUTH_USER = 'AUTH_USER'
export const AUTH_USER_IS_LOGGED_IN = 'USER_IS_LOGGED_IN'

// Roles
export const AUTH_USER_IS_ADMIN = 'USER_IS_ADMIN'
export const AUTH_USER_IS_SUPPORT = 'AUTH_USER_IS_SUPPORT'

// Mutations
export const SET_AUTH_USER = 'SET_AUTH_USER'

// Actions
export const LOGIN_USER = 'LOGIN_USER'
export const LOGOUT_USER = 'LOGOUT_USER'
///////////////////////////////////////////////////////////////////////////////

////////////////////////// USERS //////////////////////////
// Getters
export const USER = 'USER'
export const USERS = 'USERS'
export const USERS_TOTAL = 'USERS_TOTAL'

// Mutations
export const ADD_USER = 'ADD_USER'
export const SET_USER = 'SET_USER'
export const SET_USERS = 'SET_USERS'
export const SET_USERS_TOTAL = 'SET_USERS_TOTAL'

// Actions
export const GET_USER = 'GET_USER'
export const GET_USERS = 'GET_USERS'
export const SAVE_USER = 'SAVE_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const DELETE_USER = 'DELETE_USER'
export const UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD'
///////////////////////////////////////////////////////////////////////////////

////////////////////////// CARS //////////////////////////
// Getters
export const CAR = 'CAR'
export const CARS = 'CARS'
export const CARS_TOTAL = 'CARS_TOTAL'

// Mutations
export const ADD_CAR = 'ADD_CAR'
export const SET_CAR = 'SET_CAR'
export const SET_CARS = 'SET_CARS'
export const SET_CARS_TOTAL = 'SET_CARS_TOTAL'

// Actions
export const GET_CAR = 'GET_CAR'
export const GET_CARS = 'GET_CARS'
export const SAVE_CAR = 'SAVE_CAR'
export const UPDATE_CAR = 'UPDATE_CAR'
export const DELETE_CAR = 'DELETE_CAR'
///////////////////////////////////////////////////////////////////////////////

////////////////////////// SESSIONS //////////////////////////
// Getters
export const SESSION = 'SESSION'
export const SESSIONS = 'SESSIONS'
export const SESSIONS_TOTAL = 'SESSIONS_TOTAL'

//Mutations
export const SET_SESSIONS = 'SET_SESSIONS'

//Actions
export const GET_SESSION = 'GET_SESSION'
export const GET_SESSIONS = 'GET_SESSIONS'
export const GET_SESSIONS_TOTAL = 'GET_SESSIONS_TOTAL'
export const SAVE_SESSION = 'SAVE_SESSION'
export const UPDATE_SESSION = 'UPDATE_SESSION'
///////////////////////////////////////////////////////////////////////////////

////////////////////////// FILTERS //////////////////////////
// Getters
export const FILTER = 'FILTER'
export const FILTERS = 'FILTERS'
export const FILTERS_TOTAL = 'FILTERS_TOTAL'

// Mutations
export const ADD_FILTER = 'ADD_FILTER'
export const SET_FILTER = 'SET_FILTER'
export const SET_FILTERS = 'SET_FILTERS'
export const SET_FILTERS_TOTAL = 'SET_FILTERS_TOTAL'

// Actions
export const GET_FILTER = 'GET_FILTER'
export const GET_FILTERS = 'GET_FILTERS'
export const SAVE_FILTER = 'SAVE_FILTER'
export const UPDATE_FILTER = 'UPDATE_FILTER'
export const DELETE_FILTER = 'DELETE_FILTER'
///////////////////////////////////////////////////////////////////////////////

////////////////////////// FILTER_VALUES //////////////////////////
// Getters
export const FILTER_VALUE = 'FILTER_VALUE'
export const FILTER_VALUES = 'FILTER_VALUES'
export const FILTER_VALUES_TOTAL = 'FILTER_VALUES_TOTAL'

// Mutations
export const ADD_FILTER_VALUE = 'ADD_FILTER_VALUE'
export const SET_FILTER_VALUE = 'SET_FILTER_VALUE'
export const SET_FILTER_VALUES = 'SET_FILTER_VALUES'
export const SET_FILTER_VALUES_TOTAL = 'SET_FILTER_VALUES_TOTAL'

// Actions
export const GET_FILTER_VALUE = 'GET_FILTER_VALUE'
export const GET_FILTER_VALUES = 'GET_FILTER_VALUES'
export const SAVE_FILTER_VALUE = 'SAVE_FILTER_VALUE'
export const UPDATE_FILTER_VALUE = 'UPDATE_FILTER_VALUE'
export const DELETE_FILTER_VALUE = 'DELETE_FILTER_VALUE'
///////////////////////////////////////////////////////////////////////////////

////////////////////////// MAKES //////////////////////////
// Getters
export const MAKE = 'MAKE'
export const MAKES = 'MAKES'
export const MAKES_TOTAL = 'MAKES_TOTAL'

// Mutations
export const ADD_MAKE = 'ADD_MAKE'
export const SET_MAKE = 'SET_MAKE'
export const SET_MAKES = 'SET_MAKES'
export const SET_MAKES_TOTAL = 'SET_MAKES_TOTAL'

// Actions
export const GET_MAKE = 'GET_MAKE'
export const GET_MAKES = 'GET_MAKES'
export const SAVE_MAKE = 'SAVE_MAKE'
export const UPDATE_MAKE = 'UPDATE_MAKE'
export const DELETE_MAKE = 'DELETE_MAKE'
///////////////////////////////////////////////////////////////////////////////

////////////////////////// REQUESTS //////////////////////////
// Getters
export const REQUEST = 'REQUEST'
export const REQUESTS = 'REQUESTS'
export const REQUESTS_TOTAL = 'REQUESTS_TOTAL'

// Mutations
export const ADD_REQUEST = 'ADD_REQUEST'
export const SET_REQUEST = 'SET_REQUEST'
export const SET_REQUESTS = 'SET_REQUESTS'
export const SET_REQUESTS_TOTAL = 'SET_REQUESTS_TOTAL'

// Actions
export const GET_REQUEST = 'GET_REQUEST'
export const GET_REQUESTS = 'GET_REQUESTS'
export const SAVE_REQUEST = 'SAVE_REQUEST'
export const UPDATE_REQUEST = 'UPDATE_REQUEST'
export const DELETE_REQUEST = 'DELETE_REQUEST'
///////////////////////////////////////////////////////////////////////////////

////////////////////////// CLIENTS //////////////////////////
// Getters
export const CLIENT = 'CLIENT'
export const CLIENTS = 'CLIENTS'
export const CLIENTS_TOTAL = 'CLIENTS_TOTAL'

// Mutations
export const ADD_CLIENT = 'ADD_CLIENT'
export const SET_CLIENT = 'SET_CLIENT'
export const SET_CLIENTS = 'SET_CLIENTS'
export const SET_CLIENTS_TOTAL = 'SET_CLIENTS_TOTAL'

// Actions
export const GET_CLIENT = 'GET_CLIENT'
export const GET_CLIENTS = 'GET_CLIENTS'
export const SAVE_CLIENT = 'SAVE_CLIENT'
export const UPDATE_CLIENT = 'UPDATE_CLIENT'
export const DELETE_CLIENT = 'DELETE_CLIENT'
///////////////////////////////////////////////////////////////////////////////
