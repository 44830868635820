import axios from '@/axios'
import router from '@/router'

import {
  SESSIONS,
  GET_SESSIONS,
  SESSIONS_TOTAL,
  SET_SESSIONS,
  UPDATE_SESSION,
} from '@/store/types'
import { showNotification } from '@/utils'

export default {
  state: {
    sessions: {},
    sessionsTotal: 0,
  },

  getters: {
    [SESSIONS]: state => state.sessions,
    [SESSIONS_TOTAL]: state => state.sessionsTotal,
  },

  mutations: {
    [SESSIONS_TOTAL]: (state, total) => (state.sessionsTotal = total),
    [SET_SESSIONS]: (state, sessions) => (state.sessions = sessions),
    [UPDATE_SESSION]: (state, updatedSession) => {
      const sessionIndex = state.sessions.findIndex(
        sessions => sessions.id === updatedSession.id,
      )

      sessionIndex !== -1 &&
        state.sessions.splice(sessionIndex, 1, updatedSession)
    },
  },

  actions: {
    [GET_SESSIONS]: async ({ commit }, params) => {
      try {
        const { data } = await axios.post('/sessions', {
          params,
        })

        commit(SET_SESSIONS, data.result)
        commit(SESSIONS_TOTAL, data.total)
      } catch (error) {
        showNotification(
          'Ошибка',
          error.response ? error.response.data.message : error.message,
          'error',
        )
      }
    },

    [UPDATE_SESSION]: async ({ commit }, sessions) => {
      try {
        await axios.patch(`/sessions/update/${sessions.id}`, sessions)

        commit(UPDATE_SESSION, sessions)

        router.push({ name: 'sessions' }).catch(() => {})

        showNotification('Успешно', 'Сессия успешно обновлена', 'success')
      } catch (error) {
        showNotification(
          'Ошибка',
          error.response ? error.response.data.message : error.message,
          'error',
        )
      }
    },
  },
}
